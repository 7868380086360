import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import messages from './messages.mjs';

export default function getCategoryPath(category, hasLocalNews, intl) {
  const path = category.path.replace(/\/.+/, '');

  return capitalize(hasLocalNews
    ? path
    : intl.formatMessage(messages[camelCase(path)]));
}
